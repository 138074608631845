import { useEffect, useState, useCallback, createContext, useContext } from "react";
import { useNavigate } from "react-router";
import axios from "axios";

// mui components
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// contexto
import { useMessagesContext } from "./";
// global
import { URLS } from "../global";

export const AuthContext = createContext();

/**
 * Funcion/hook para utilizar el contexto de requests
 * @returns {Object} Objeto con los estados y funciones del contexto, contiene lo siguiente:
 * @param {Boolean} isAuthenticated Flag de autenticación
 * @param {Object} axiosInstance Instancia de axios con la configuración de la API
 * @param {String} userData Datos del usuario
 * @param {String} username Nombre de usuario
 * @param {String} USER_TYPE Tipo de usuario
 * @param {String} first_name Nombre del usuario
 * @param {String} last_name Apellido del usuario
 * @param {Function} login Función para realizar el login
 * @param {Function} logout Función para realizar el logout
 * @param {Function} verify Función para verificar el token
 */
export const useAuthContext = () => useContext(AuthContext);

const baseURL = URLS.backend;
const default_headers = {
	"Content-Type": "application/json",
	accept: "application/json",
};
const storage_keys = [
	"access_token",
	"refresh_token",
	"username",
	"first_name",
	"last_name",
	"USER_TYPE",
	"id",
	"canDelete",
	"canUseMapTools",
];

/**
 * Componente para proveer el contexto de autenticación
 * @param {Object} children Componentes hijos
 */
const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const { addMessage } = useMessagesContext();

	const [isAuthenticated, setIsAuthenticated] = useState(false);

	// Flag de recuerdo de sesión (true: localStorage, false: sessionStorage)
	const [remember, setRemember] = useState(localStorage.getItem("remember"));

	// Storage a utilizar para guardar los tokens
	const [storage, setStorage] = useState(remember === "true" ? localStorage : sessionStorage);

	// Tokens de acceso y refresco
	const [tokens, setTokens] = useState({
		access_token: storage.getItem("access_token") ? storage.getItem("access_token") : undefined,
		refresh_token: storage.getItem("refresh_token") ? storage.getItem("refresh_token") : undefined,
	});

	const createInstance = useCallback(() => {
		// Configurar los headers de la instancia de axios
		let headers = default_headers;
		if (tokens?.access_token) headers["Authorization"] = `JWT ${tokens.access_token}`;

		// Crear la instancia de axios
		return axios.create({
			baseURL: baseURL,
			timeout: 3000000,
			withCredentials: true,
			headers: headers,
		});
	}, [tokens]);

	// Instancia de axios para realizar las peticiones a la API
	const [axiosInstance] = useState(createInstance);

	// Datos del usuario
	const [userData, setUserData] = useState({
		username: storage.getItem("username") ? storage.getItem("username") : "",
		first_name: storage.getItem("first_name") ? storage.getItem("first_name") : "",
		last_name: storage.getItem("last_name") ? storage.getItem("last_name") : "",
		USER_TYPE: storage.getItem("USER_TYPE") ? storage.getItem("USER_TYPE") : "",
		id: storage.getItem("id") ? storage.getItem("id") : "",
		canDelete: storage.getItem("canDelete") ? storage.getItem("canDelete") : "",
		canUseMapTools: storage.getItem("canUseMapTools") ? storage.getItem("canUseMapTools") : "",
	});
	/**
	 * Función para limpiar los tokens y cambiar el estado de autenticación
	 */
	const clearTokens = useCallback(() => {
		// remover todo de localStorage y sessionStorage
		storage_keys.forEach((key) => {
			localStorage.removeItem(key);
			sessionStorage.removeItem(key);
		});
		setStorage(sessionStorage);
		setTokens({
			access_token: undefined,
			refresh_token: undefined,
		});
		setUserData({
			username: "",
			first_name: "",
			last_name: "",
			USER_TYPE: "",
			id: "",
			canDelete: false,
			canUseMapTools: false,
		});
		axiosInstance.defaults.headers["Authorization"] = undefined;
		setIsAuthenticated(false);
	}, [axiosInstance]);
	/**
	 * Función para refrescar el token de acceso
	 */
	const refreshToken = useCallback(async () => {
		return axiosInstance
			.post(URLS.refresh_token, { refresh: tokens.refresh_token })
			.then((response) => {
				let new_tokens = {
					access_token: tokens.access_token,
					refresh_token: tokens.refresh_token,
				};
				new_tokens.access_token = response.data.access;
				if (response.data.refresh) new_tokens.refresh_token = response.data.refresh;
				// guardar los tokens
				setTokens(new_tokens);
				// guardar los tokens en el storage
				storage.setItem("access_token", response.data.access);
				if (response.data.refresh) storage.setItem("refresh_token", response.data.refresh);
				axiosInstance.defaults.headers["Authorization"] = `JWT ${response.data.access}`;
				setIsAuthenticated(true);
				return Promise.resolve(response);
			})
			.catch((err) => {
				// Si el refresco falló, limpiar los tokens
				clearTokens();
				return Promise.reject(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [axiosInstance, storage, clearTokens]);
	/**
	 * Interceptor de requests para manejar los errores de autenticación
	 */
	axiosInstance.interceptors.response.use(
		(response) => response, // Si la respuesta es exitosa, retornarla sin cambios
		async (error) => {
			// Si la respuesta es un error, continuar
			// guardar la configuración de la solicitud original
			const originalRequest = error.config;

			if (typeof error.response === "undefined") {
				// No hubo respuesta del servidor
				addMessage("No se pudo conectar con el servidor", "error");
				alert(
					"No se pudo conectar con el servidor. " +
						"Es posible que el problema sea CORS. " +
						"Si el problema persiste, contacte al administrador."
				);
				return Promise.reject(error);
			}

			if (
				// Si el error es 401 y fue para refrescar el token,
				// Estamos en el caso en que el token de refresco expiró o es inválido
				error.response.status === 401 &&
				originalRequest.url === URLS.refresh_token
			) {
				// redirigir a login
				navigate("/login");
				return Promise.reject(error);
			} else if (
				// token de acceso inválido
				error.response.status === 401 &&
				error.response.data.code === "token_not_valid"
			) {
				// solicitar un nuevo token de acceso
				return refreshToken()
					.then((_response) => {
						// crear una nueva promesa con timeout para evitar hacer spam de requests
						return new Promise((resolve, reject) => {
							setTimeout(() => {
								// rehacer la solicitud original con el nuevo token de acceso
								const actualualizedToken = storage.getItem("access_token");
								originalRequest.headers.Authorization = actualualizedToken.access_token
									? `JWT ${actualualizedToken.access_token}`
									: undefined;
								axiosInstance.defaults.headers["Authorization"] = actualualizedToken.access_token
									? `JWT ${actualualizedToken.access_token}`
									: undefined;
								return axiosInstance(originalRequest)
									.then((res) => {
										return resolve(res);
									})
									.catch((err) => {
										return reject(err);
									});
							}, 500); // Esperar 100ms para evitar hacer spam de requests
						})
							.then((res) => {
								// La solicitud original fue exitosa
								return Promise.resolve(res);
							})
							.catch((err) => {
								// La solicitud original falló
								return Promise.reject(err);
							});
					})
					.catch((err) => {
						// Si el refresco falló, redirigir a login
						navigate("/login");
						return Promise.reject(err);
					});
			} else if (
				// Usuario inactivo
				error.response.status === 401 &&
				error.response.data.code === "user_inactive"
			) {
				// Limpiar los tokens y redirigir a login
				clearTokens();
				navigate("/login");
				addMessage("Su usuario está inactivo en la plataforma o fue eliminado", "error");
				return Promise.reject(error);
			} else if (
				// autenticacion no proveida
				error.response.status === 401 &&
				!originalRequest.headers.Authorization
			) {
				// redirigir a login
				navigate("/login");
				// addMessage("Debe iniciar sesión para acceder a esta página", "error");
				return Promise.reject(error);
			}

			// Retornar el error si no es ninguno de los casos anteriores
			return Promise.reject(error);
		}
	);

	/**
	 * Función para guardar los datos correspondientes luego de realizar el login
	 */
	const onLogin = useCallback(
		(remember, access, refresh, username, first_name, last_name, USER_TYPE, id, canDelete, canUseMapTools) => {
			localStorage.setItem("remember", remember);
			if (remember) {
				storage_keys.forEach((key) => sessionStorage.removeItem(key));

				localStorage.setItem("access_token", access);
				localStorage.setItem("refresh_token", refresh);
				localStorage.setItem("username", username);
				localStorage.setItem("first_name", first_name);
				localStorage.setItem("last_name", last_name);
				localStorage.setItem("USER_TYPE", USER_TYPE);
				localStorage.setItem("id", id);
				localStorage.setItem("canDelete", canDelete);
				localStorage.setItem("canUseMapTools", canUseMapTools);
			} else {
				storage_keys.forEach((key) => localStorage.removeItem(key));
				localStorage.setItem("remember", remember);

				sessionStorage.setItem("access_token", access);
				sessionStorage.setItem("refresh_token", refresh);
				sessionStorage.setItem("username", username);
				sessionStorage.setItem("first_name", first_name);
				sessionStorage.setItem("last_name", last_name);
				sessionStorage.setItem("USER_TYPE", USER_TYPE);
				sessionStorage.setItem("id", id);
				sessionStorage.setItem("canDelete", canDelete);
				sessionStorage.setItem("canUseMapTools", canUseMapTools);
			}
			setRemember(remember);
			setStorage(remember ? localStorage : sessionStorage);
			setTokens({
				access_token: access,
				refresh_token: refresh,
			});
			setUserData({
				username: username,
				first_name: first_name,
				last_name: last_name,
				USER_TYPE: USER_TYPE,
				id: id,
				canDelete: canDelete,
				canUseMapTools: canUseMapTools,
			});
			axiosInstance.defaults.headers["Authorization"] = access ? `JWT ${access}` : undefined;
			setIsAuthenticated(true);
		},
		[axiosInstance]
	);

	/**
	 * Función para realizar el login
	 * @param {String} username Nombre de usuario
	 * @param {String} password Contraseña
	 * @param {Boolean} remember Flag para recordar la sesión
	 * @returns {Promise} Promesa con la respuesta de la API
	 */
	const login = useCallback(
		async (username, password, remember) => {
			return axiosInstance
				.post(URLS.login, {
					username: username,
					password: password,
					remember: remember,
				})
				.then((res) => {
					// guardar los tokens en el storage
					onLogin(
						remember,
						res.data.access,
						res.data.refresh,
						username,
						res.data.first_name,
						res.data.last_name,
						res.data.usertype,
						res.data.id,
						res.data.can_delete,
						res.data.can_use_map_tools
					);
					return Promise.resolve(res);
				})
				.catch((e) => {
					return Promise.reject(e);
				});
		},
		[axiosInstance, onLogin]
	);

	/**
	 * Función para cerrar sesión
	 * @returns {Promise} Promesa con la respuesta de la API
	 */
	const logout = useCallback(async () => {
		return axiosInstance
			.post(URLS.logout, { refresh: tokens.refresh_token })
			.then((response) => {
				clearTokens();
				addMessage(response.data.message, response.data.severity);
				navigate("/login");
				return Promise.resolve(response);
			})
			.catch((error) => {
				clearTokens();
				// addMessage(error.response.data.error, error.response.data.severity);
				navigate("/login");
				// console.error(error);
				return Promise.reject(error);
			});
	}, [axiosInstance, tokens.refresh_token, clearTokens, addMessage, navigate]);

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		// refrescar token
		if (tokens.refresh_token) {
			refreshToken();
		} else {
			clearTokens();
			navigate("/login");
		}
		setInitialized(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated: isAuthenticated,
				axiosInstance: axiosInstance,

				userData: userData,
				username: userData.username,
				first_name: userData.first_name,
				last_name: userData.last_name,
				USER_TYPE: userData.USER_TYPE,
				canDelete: userData.canDelete,
				canUseMapTools: userData.canUseMapTools,

				login: login,
				logout: logout,
			}}
		>
			{!initialized && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
						gap: "10px",
					}}
				>
					<CircularProgress />
					<Typography variant="h5" color="textSecondary">
						Cargando...
					</Typography>
				</div>
			)}
			{children}
		</AuthContext.Provider>
	);
};
export default AuthProvider;
