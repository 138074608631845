import { useAuthContext, useMessagesContext } from "../../context";
import { URLS } from "../../global";
import GeneralButton from "../GeneralButton";
import CircularCheckbox from "../Inputs/CircularCheckbox";
import TextInput from "../Inputs/TextInput";
import { useState } from "react";

export const Backup = () => {
	const [month, setMonth] = useState(null);
	const [consolidated, setConsolidated] = useState(false);
	const [receptionNotes, setReceptionNotes] = useState(false);
	const { axiosInstance } = useAuthContext();
	const [loading, setLoading] = useState(false);
	const { addMessage } = useMessagesContext();

	const createBackup = () => {
		setLoading(true);
		axiosInstance
			.get(
				`${URLS.BACKUP}?year=${month.split("-")[0]}&month=${
					month.split("-")[1]
				}&consolidated=${consolidated}&receptionNotes=${receptionNotes}`,
				{
					responseType: "arraybuffer",
				}
			)
			.then((res) => {
				const blob = new Blob([res.data, { type: "application/zip" }]);
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `backup-${month}`;
				a.setAttribute("download", `backup-${month}.zip`);
				a.click();
				addMessage("Copia de seguridad realizada con éxito", "success");
			})
			.catch((err) => {
				addMessage("Error al realizar la copia de seguridad", "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div
			style={{
				padding: "20px",
				display: "flex",
				flexDirection: "column",
				gap: "10px",
			}}
		>
			Seleccione un mes para realizar la copia de seguridad
			<TextInput type="month" value={month} onChange={(e) => setMonth(e.target.value)} loading={loading} />
			<CircularCheckbox
				checked={consolidated}
				onChange={setConsolidated}
				disabled={loading}
				label="Incluir consolidados"
				variant="square"
			/>
			<CircularCheckbox
				checked={receptionNotes}
				onChange={setReceptionNotes}
				disabled={loading}
				label="Incluir notas de recepción"
				variant="square"
			/>
			<GeneralButton text="Realizar copia de seguridad" onClick={createBackup} disabled={loading || !month} />
		</div>
	);
};
