import "leaflet/dist/leaflet.css";
import "./Map.css";
import { useEffect, useState, useRef, Fragment, useDeferredValue, useMemo } from "react";
import L from "leaflet";

// mui components
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import html2canvas from "html2canvas";

// mui icons
import CameraAltIcon from "@mui/icons-material/CameraAlt";

// leaflet components
import {
	MapContainer,
	TileLayer,
	GeoJSON,
	Popup,
	useMapEvents,
	ScaleControl,
	ZoomControl,
	Marker,
} from "react-leaflet";

// cluster
import MarkerClusterGroup from "react-leaflet-cluster";

// utils
import { feature_type_map } from "../../utils/map_helper";

// components
import MinimapControl from "./Minimap";
import Legend from "./Legend";
import { FeedingBacteriumImages, ObjectImages, ProspectionImages, VideoPopups } from "./StyledComponents";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import BoatIcon from "../Icons/BoatIcon";
import { renderToString } from "react-dom/server";

/**
 * MapEvents: Componente para manejar los eventos del mapa
 * @param {function} setZoom: funcion para setear el estado del zoom
 * @param {function} setCenter: funcion para setear el estado del centro (del mapa)
 */
function MapEvents({ setZoom, setCenter, clickHandler = undefined }) {
	useMapEvents({
		zoomend: (e) => {
			setZoom && setZoom(e.target.getZoom());
		},
		moveend: (e) => {
			setCenter && setCenter(e.target.getCenter());
		},
		click: (e) => {
			clickHandler && clickHandler(e);
		},
	});

	return null;
}

const createBoatDivIcon = (feature) => {
	const boatIcon = L.divIcon({
		className: "boat-div-icon", // You can style this class in your CSS
		iconSize: [40, 40], // Set the size of the icon
		iconAnchor: [20, 20], // Set the anchor point for the icon
		html: renderToString(
			<DirectionsBoatFilledIcon sx={{ fontSize: "70px", width: "70px", height: "70px" }} fill="var(--primary)" />
		),
	});

	return boatIcon;
};

const ObjectsMarkers = ({ objects, clusterRadius, images, videos, feedingBacteriumImages, maxHeight }) => {
	return (
		<Fragment>
			<ProspectionImages images={images?.features || []} clusterRadius={clusterRadius} maxHeight={maxHeight} />

			<FeedingBacteriumImages
				images={feedingBacteriumImages?.features || []}
				clusterRadius={clusterRadius}
				maxHeight={maxHeight}
			/>

			<VideoPopups images={videos?.features || []} clusterRadius={clusterRadius} maxHeight={maxHeight} />

			<ObjectImages images={objects?.features || []} clusterRadius={clusterRadius} maxHeight={maxHeight} />
		</Fragment>
	);
};

const MarkerClusters = ({ clustering, config, children }) => {
	return (
		<Fragment key={clustering}>
			<MarkerClusterGroup
				maxClusterRadius={clustering}
				chunkedLoading={true}
				disableClusteringAtZoom={config.initial_is_clustered ? config.maxZoom : 0}
				iconCreateFunction={(cluster) => {
					return L.divIcon({
						html: `<div><span>${cluster.getChildCount()}</span></div>`,
						iconSize: L.point(40, 40, true),
						className: `marker-cluster marker-cluster-blue`,
					});
				}}
			>
				{children}
			</MarkerClusterGroup>
		</Fragment>
	);
};

/**
 * Map: Component to render the map
 * @param {object} initial_state: object with the initial state of the map
 * @param {object} data: object with the data to render, contains the objects and structures
 * @param {boolean} loading: boolean to indicate if the data is loading
 * @param {object} externalCenter: object with the center of the map, to use the same across different renders
 * @param {function} setOuterZoom: function to set the zoom of the outer map, to use the same across different renders
 * @param {function} setOuterCenter: function to set the center of the outer map, to use the same across different renders
 * @param {object} sx: object with the styles to apply to the map
 * @param {function} onMapClick: function to handle the click event on the map
 * @param {function} setMapImage: function to set the image of the map when a screenshot is taken
 */
function Map({
	initial_state,
	data,
	loading,
	externalCenter,
	title = "",
	setOuterZoom = () => {},
	setOuterCenter = () => {},
	sx = {},
	onMapClick = undefined,
	setMapImage = undefined,
	smallLegends,
	mapKey,
}) {
	// initial config
	const [config] = useState({
		initial_cluster_radius: initial_state?.initial_cluster_radius ? initial_state.initial_cluster_radius : 12,
		initial_is_clustered:
			initial_state && Object.keys(initial_state).includes("initial_is_clustered")
				? initial_state.initial_is_clustered
				: true,
		initial_zoom: initial_state?.initial_zoom ? initial_state.initial_zoom : 6,
		initial_center: initial_state?.initial_center ? initial_state.initial_center : [-44.1013, -73.6532],
		maxBounds: initial_state?.maxBounds
			? initial_state.maxBounds
			: [
					[90, -180 * 1.5],
					[-90, 180 * 1.5],
			  ],
		maxNativeZoom: initial_state?.maxNativeZoom ? initial_state.maxNativeZoom : 18,
		maxZoom: initial_state?.maxZoom ? initial_state.maxZoom : 22,
		minZoom: initial_state?.minZoom ? initial_state.minZoom : 1,
		tilemap_url: initial_state?.tilemap_url
			? initial_state.tilemap_url
			: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		tilemap_attribution: initial_state?.tilemap_attribution ? initial_state.tilemap_attribution : "",
	});
	// states
	const [zoom, setZoom] = useState(config.initial_zoom);
	const [center, setCenter] = useState(config.initial_center);

	const [clustering, setClustering] = useState(config.initial_cluster_radius);
	const deferedClustering = useDeferredValue(clustering);

	useEffect(() => {
		setClustering(initial_state?.initial_cluster_radius ? initial_state.initial_cluster_radius : 12);
	}, [initial_state]);

	// data states
	const [objects, setObjects] = useState(data?.objects);
	const [structures, setStructures] = useState(data?.structures);
	const [images, setImages] = useState(data?.images);
	const [boats, setBoats] = useState(data?.boats);
	const [videos, setVideos] = useState(data?.videos);
	const [feedingBacteriumImages, setFeedingBacteriumImages] = useState(data?.feedingBacteriumImages);
	const mapRef = useRef();
	/* update the objects and structures when the data changes */
	useEffect(() => {
		setObjects(data?.objects);
		setStructures(data?.structures);
		setImages(data?.images);
		setBoats(data?.boats);
		setVideos(data?.videos);
		setFeedingBacteriumImages(data?.feedingBacteriumImages);
	}, [data]);

	const [mapref, setMapref] = useState();

	useEffect(() => {
		if (externalCenter && mapref) {
			mapref.flyTo(externalCenter, 19);
		}
	}, [externalCenter, mapref]);

	useEffect(() => {
		if (mapref) {
			mapref.invalidateSize();
		}
	}, [mapref, mapKey]);

	const [clusterRadius, setClusterRadius] = useState(40);
	const deferredClusterRadius = useDeferredValue(clusterRadius);

	const onEachFeature = (feature, layer, object) => {
		if (object.properties.data_type === feature_type_map.Jaula && object.properties.name) {
			layer.bindTooltip("<div class='tooltip-jail'>" + String(object.properties.name) + "</div>", {
				permanent: true,
				direction: "center",
				className: "leaflet-tooltip-own",
			});
		} else if (object.properties.data_type === feature_type_map.Poligono && object.properties.name) {
			layer.bindTooltip("<div class='tooltip-jail'>" + String(object.properties.name) + "</div>", {
				permanent: true,
				direction: "center",
				className: "leaflet-tooltip-own",
			});
		}
	};
	const zoomRef = useRef();

	const captureScreenshot = (downloadImage, setImage) => {
		const mapElement = mapRef.current._container;
		zoomRef.current._container.style.display = "none";
		if (mapElement) {
			html2canvas(mapElement, {
				useCORS: true,
				allowTaint: true,
				removeContainer: false,
				scale: 3,
			}).then((canvas) => {
				zoomRef.current._container.style.display = "block";
				if (downloadImage) {
					const imgData = canvas.toDataURL("image/png");
					const link = document.createElement("a");
					link.href = imgData;
					link.download = "map-screenshot.png";
					link.click();
				}
				if (setImage) {
					const blob = canvas.toBlob((blob) => {
						setImage(blob);
					});
				}
			});
		}
	};

	const containerRef = useRef();

	return (
		<Box
			ref={containerRef}
			id="mpa111"
			sx={{
				position: "relative",
				width: "100%",
				height: "100%",
				margin: "auto",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				...sx,
			}}
			className="map"
		>
			{!loading ? (
				<>
					<IconButton
						size="large"
						onClick={() =>
							captureScreenshot(setMapImage ? false : true, setMapImage ? setMapImage : () => {})
						}
						component="div"
						sx={{
							width: 52,
							height: 52,
							zIndex: 1000,
							position: "absolute",
							bottom: "140px",
							right: "10px",
							backgroundColor: "white",
							color: "#101828",
						}}
					>
						<CameraAltIcon />
					</IconButton>
					<Box
						sx={{
							fontSize: "11px",
							height: "70px",
							padding: "10px",
							width: "180px",
							position: "absolute",
							bottom: "10px",
							right: "75px",
							zIndex: 1000,
							backgroundColor: "white",
							border: "1px solid rgb(196,196,196)",
							borderRadius: 2,
							"@media (maxWidth: 600px)": {
								bottom: "25px",
							},
						}}
					>
						Radio del marcador de objeto
						<Slider
							size="small"
							value={clusterRadius}
							onChange={(_, value) => setClusterRadius(value)}
							min={10}
							max={100}
							sx={{}}
						></Slider>
					</Box>
					<MapContainer
						zoom={zoom}
						center={center}
						maxBounds={config.maxBounds}
						attributionControl={false}
						zoomControl={false}
						whenReady={(e) => {
							setMapref(e.target);
						}}
						ref={mapRef}
						preferCanvas={true}
					>
						<TileLayer
							attributionControl={false}
							url={config.tilemap_url}
							maxNativeZoom={config.maxNativeZoom}
							maxZoom={config.maxZoom}
							minZoom={config.minZoom}
							preferCanvas={true}
						/>

						<MapEvents
							setZoom={(zoom) => {
								setZoom(zoom);
								setOuterZoom(zoom);
							}}
							setCenter={(center) => {
								setCenter(center);
								setOuterCenter(center);
							}}
							clickHandler={onMapClick}
						/>
						<MinimapControl position={"topright"} zoomLevelOffset={-6} />
						<ScaleControl
							position="bottomleft"
							maxWidth={200}
							imperial={false}
							style={{
								backgroundColor: "red",
								color: "red",
								padding: "5px",
								borderRadius: "10px",
							}}
						/>
						<ZoomControl position="bottomright" ref={zoomRef} />
						<Box
							sx={{
								position: "absolute",
								bottom: "30px",
								left: "10px",
								zIndex: 1000,
								"@media (maxWidth: 600px)": {
									top: "10px",
								},
							}}
						>
							<Legend small={setMapImage !== undefined || smallLegends} />
						</Box>
						{title && (
							<Box
								sx={{
									position: "absolute",
									top: "10px",
									left: "50%",
									zIndex: 1000000,
									width: "100%",
									textAlign: "center",
								}}
							>
								<Typography variant="h6" sx={{ position: "relative", left: "-50%", width: "100%" }}>
									{title}
								</Typography>
							</Box>
						)}

						{/* Here starts the unclustered data */}
						{structures?.features?.map((feature) => {
							// Renderizar solamente los centros con un zoom menor a 12
							if (zoom > 14 || feature.properties.data_type === feature_type_map.Centro) {
								return (
									<GeoJSON
										key={feature.properties.data_type + "_" + feature.properties.id}
										data={feature.geometry}
										style={{
											color: feature.properties.color,
											// fillOpacity: zoom < 20 ? 0.2 : 0.05, // aumentar la opacidad cuando se hace zoom
										}}
										onEachFeature={(feat, layer) => onEachFeature(feat, layer, feature)}
									>
										{/* Solo mostrar el popup si el zoom es menor a 20 */}
										{zoom < 20 && (
											<Popup>
												<div
													style={{
														backgroundColor: "white",
														padding: "20px",
														borderRadius: "12px",
														width: "100%",
													}}
												>
													{feature?.properties?.name && (
														<Typography my={0} variant="h6">
															{feature.properties.name}
														</Typography>
													)}

													{feature.properties.data_type === feature_type_map.Jaula && (
														<Stack direction="column" spacing={1}>
															{feature?.properties?.cultivationCenter && (
																<Typography my={0} variant="body2">
																	Centro: {feature.properties.cultivationCenter}
																</Typography>
															)}
														</Stack>
													)}

													{feature.properties.data_type === feature_type_map.Centro && (
														<Stack direction="column" spacing={1}>
															{feature?.properties?.code && (
																<Typography my={0} variant="body2">
																	Codigo: {feature.properties.code}
																</Typography>
															)}
															{feature?.properties?.salmonCompany?.name && (
																<Typography my={0} variant="body2">
																	Empresa productora:{" "}
																	{feature.properties.salmonCompany.name}
																</Typography>
															)}
														</Stack>
													)}

													{feature.properties.data_type === feature_type_map.Poligono && (
														<Stack direction="column" spacing={1}>
															{feature?.properties?.description && (
																<Typography my={0} variant="body2">
																	Descripcion: {feature.properties.description}
																</Typography>
															)}
															{feature?.properties?.cultivationCenter && (
																<Typography my={0} variant="body2">
																	Centro: {feature.properties.cultivationCenter}
																</Typography>
															)}
														</Stack>
													)}
												</div>
											</Popup>
										)}
									</GeoJSON>
								);
							}
							return null;
						})}
						{/* end */}
						{boats?.features?.map((feature) => (
							<Marker
								key={feature.properties.data_type + "_" + feature.properties.name}
								position={feature.geometry.coordinates}
								icon={createBoatDivIcon(feature)}
								opacity={feature.properties.opacity || 0.7}
							>
								<Popup>
									<div
										style={{
											backgroundColor: "white",
											padding: "5px",
											borderRadius: "12px",
											width: "100%",
										}}
									>
										<Typography my={0} variant="h6">
											Embarcación {feature.properties.name}
										</Typography>
									</div>
								</Popup>
							</Marker>
						))}
						<MarkerClusters
							clustering={deferedClustering}
							clusterRadius={deferredClusterRadius}
							config={config}
						>
							<ObjectsMarkers
								objects={objects}
								images={images}
								videos={videos}
								feedingBacteriumImages={feedingBacteriumImages}
								clusterRadius={deferredClusterRadius}
								maxHeight={containerRef.current?.clientHeight}
							/>
						</MarkerClusters>
					</MapContainer>
				</>
			) : (
				<CircularProgress />
			)}
		</Box>
	);
}

export default Map;
